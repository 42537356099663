// @ts-nocheck
import Gallery from "react-photo-gallery";
import {photos} from "./photos";
import ProfilePicture from "../../Common/Models/ProfilePicture";

type PicturesGalleriaProps = {
  pictures: ProfilePicture[],
  hide?: boolean
}

const PicturesGalleria = (
  {
    pictures,
    hide = true
  }: PicturesGalleriaProps
) => {

  if(!pictures || pictures.length < 1) {
    pictures = photos
  }

  if(hide) return null


  return(
    <div className={"my-6"}>
      <Gallery photos={pictures} />;
    </div>
  )
}

export default PicturesGalleria