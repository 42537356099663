import communityDetails from "../../Common/Models/CommunityDetails";
import {message} from "antd";
import {useState} from "react";

type ScoreCardProps = {
  status: 0 | 1 | 2 | 3,
  brandDetails: communityDetails | null,
  score: number,
  max: number
}

const QUALIFYING_PERCENTAGE = 0

const ScoreCard = (
  {
    status,
    brandDetails,
    score,
    max
  }: ScoreCardProps
) => {
  const [copied, setCopied] = useState(false)
  let qual = QUALIFYING_PERCENTAGE
  const CODE = "HJ27MK"

  if(status !== 3 || !brandDetails) return null

  const copyCode = () => {
    window.navigator.clipboard.writeText(CODE)
    setCopied(true)
    message.success("Coupon Code Copied")
  }

  let p = score*100 / max;

  if(p < qual) {
    return (
      <div className={"ib-card-parent"}>
        <div className={"card ib-community-card switched"}>
          <div className={"card-content ib-card-content has-text-centered"}>
            <h4 className={"title is-size-4 is-uppercase"}>
              results
            </h4>
            <div className={"p-6 is-size-1 has-text-weight-bold"}>
              TRY AGAIN!
            </div>
            <div className={"content has-text-centered pt-5 mb-5 "}>
              <p className={"has-text-weight-bold"}>
                Enjoyed the quiz?
              </p>
              Share it on Instagram and get 20% off on your next order!
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"ib-card-parent"}>
      <div className={"card ib-community-card switched"}>
        <div className={"card-content ib-card-content has-text-centered"}>
          <h4 className={"title is-size-4 is-uppercase"}>
            results
          </h4>
          <div className={"p-6 is-size-1 has-text-weight-bold"}>
            {score} / {max}
          </div>
          <div className={"content has-text-centered pt-5 mb-5 "}>
            <p className={"has-text-weight-bold"}>
              Well Played!
            </p>
            Here is a code for 20% off on your next order for playing!
          </div>

          <div>
            <p
              className={`button ${copied ? "copied" : ""} ib-reward-box has-text-weight-bold title is-size-4-tablet is-size-5`}
              onClick={() => copyCode()}
              title={"Click to Copy Code"}
            >
              {CODE}
              <i
                className="fa fa-clipboard pl-6"
                aria-hidden="true"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScoreCard