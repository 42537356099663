import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {Form, message, Result, Spin} from "antd";
import CommunityDetails from "../../Common/Models/CommunityDetails";
import {BadNotif, SuccessNotif} from "../../Common/Utils/SendNotification";
import isMobile from "is-mobile";
import {capitalize, validateEmail} from "../BrandPage/BrandPage";
import Loader from "../../components/Loader";
import SEO from "../../components/SEO";
import FAQs from "../BrandApplication/FAQs";
import GetTriviaPlayEligible from "../../Common/ApiCall/GetTriviaPlayEligible";
import ScoreCard from "./ScoreCard";
import PicturesGalleria from "../../components/PicturesGalleria";
import GridBox from "../GridBox";
import GetGameInfo from "../../Common/ApiCall/GetGameInfo";
import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

/*
status
0 - form
1 - quiz
2 - retry screen
3 - thanks for playing (won)
 */

const DEFAULT_IMAGE = "https://i.ibb.co/KjfbQmt/sullen-trivia.gif"

const QuizHome = () => {
  const [bName, setBname] = useState<string | number | null>(null)
  const [it, setIt] = useState(true)
  const [load, setLoad] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const p = useParams()
  const [iForm] = Form.useForm()
  const [done, setDone] = useState<0 | 1 | 2 | 3>(0)
  const [searchP] = useSearchParams()
  const [brandDetails, setBrandDetails] = useState<CommunityDetails | null>(null)
  const [score, setScore] = useState(0)
  const [gameId, setGameId] = useState<null | number>(null)

  useEffect(() => {
    Events.scrollEvent.register('begin', function () {});
    Events.scrollEvent.register('end', function () {});
  }, [])

  const scrollTo = () => {
    scroller.scrollTo('ib-form-main', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    })
  }

  useEffect(() => {
    getBrandDetails()
  }, [bName])

  async function getBrandDetails() {
    if(bName) {
      const res = await GetGameInfo(bName)
      setIt(false)
      if(res.success) {
        let data = res.data
        setBrandDetails({...data})
        let d = document.getElementsByClassName("ib-bg")[0];
        //@ts-ignore
        d.style.backgroundColor = res.data.theme_bg
      } else {
        BadNotif(res)
      }
    }
  }

  async function formSubmit() {
    message.warn("Campaign is in Draft Mode. Will be live in few days.")
    return

    let a = iForm.getFieldsValue()
    //console.log(a)
    let e = true
    let b = {
      email: a["ib-community-email"],
      page_id: bName,
      username: a["ib-community-instagram"],
      eligible: e ? "yes" : "no",
      mobile: isMobile() ? "yes" : "no"
    }
    if(!validateEmail(b.email)) {
      message.warn("Invalid Email.");return;
    }
    setLoad(true)
    const res = await GetTriviaPlayEligible(b)
    setLoad(false)
    if(res.success) {
      setGameId(res.data.game_id)
      setDone(1)
    } else {
      BadNotif(res)
    }
  }

  const selectItem = () => {
    setSelectedKeys([...selectedKeys, ])
  }

  const played = async (finalScore: number) => {
    setLoad(true)
    setLoad(false)
  }

  function complete(score: number) {
    setScore(score)
    played(score)
      .then(() => {
        if(isMobile()) {
          scroll.scrollTo(400)
        }
        setDone(1)
      })
  }

  useEffect(() => {
    if(p && p.brand) setBname(p.brand)
  }, [])

  useEffect(() => {
    //console.log(iForm.getFieldsValue())
  })

  function hexfix(str: string) {
    str = str.substring(1)
    if (str.length === 3) {
      str = str.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    return str
  }

  const getTextColor = (color: string) => {
    color = hexfix(color)
    let c = "#fff"
    //if(l && l < 20) c = "#fff"
    return c
  }

  if(it || !brandDetails) {
    if(it) {
      return (
        <section className="hero is-fullheight theme-bg">
          <div className="hero-body has-text-centered">
            <div className="container">
              <Loader />
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="hero is-fullheight theme-bg">
          <div className="hero-body has-text-centered">
            <div className="container">
              <Result
                status="500"
                title="500"
                subTitle="Invalid URL."
              />
            </div>
          </div>
        </section>
      )
    }
  }

  if(load) {
    return(
      <div className={"hero isheightmax theme-bg"}>
        <SEO
          title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
          img={brandDetails.brand_logo ?? ""}
        />
        <div className={"hero-body"}>
          <div className={"container"}>
            <div className={"columns is-mobile is-multiline is-vcentered"}>
              <div className={"column"}>
                <div className={"ib-card-parent"}>
                  <div
                    className={"card ib-community-card"}
                    style={{
                      minHeight: "50vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <div
                      className={"card-content ib-card-content has-text-centered"}
                      style={{
                        backgroundColor: brandDetails.bg_color,
                      }}
                    >
                      <Spin />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div className={"hero theme-bg"}>
      <SEO
        title={capitalize(brandDetails?.brand_name + " | Influencerbit Community")}
        img={brandDetails.brand_logo ?? ""}
      />
      <div className={"hero-body"}>
        <div className={"container"}>
          <div className={"columns mb-6 is-centered is-multiline is-vcentered"}>
            <div
              className={`column is-6-tablet`}
            >
              <div>
                <GridBox
                  status={done}
                  brandDetails={brandDetails}
                  complete={complete}
                />
              </div>
              <ScoreCard
                status={done}
                brandDetails={brandDetails}
                score={score}
                max={9}
              />
{/*              <div className={"ib-card-parent"}></div>*/}
            </div>
            <div
              className={`column is-6-tablet`}
              hidden={done === 0}
            >
              <div
                className={"card ib-community-card has-text-centered"}
              >
                <div
                  className={"card-content ib-card-content has-text-centered py-6"}
                  style={{
                    backgroundColor: brandDetails.bg_color
                  }}
                >
                  <h4 className={"title is-size-3"}>
                    {brandDetails.brand_title}
                  </h4>
                  <div className={"content"}>
                    {brandDetails.brand_description}
                    <br /><br />
                    <Element
                      name={"ib-form-main"}
                      className={"element"}
                    />
                    <Form
                      className={"has-text-left"}
                      form={iForm}
                      onFinish={() => formSubmit()}
                    >
                      <Form.Item
                        noStyle
                      >
                        <Form.Item
                          name={"ib-community-email"}
                          rules={[{
                            required: true, type: 'email'
                          }]}
                          noStyle
                        >
                        </Form.Item>
                      </Form.Item>
                      <div
                        className={"ib-form-email-comp"}
                      >
                        <i
                          className="fa fa-ig-ib fa-2x fa-envelope"
                          aria-hidden="true"
                        />
                        <input
                          name={"email"}
                          type={"email"}
                          className={"ib-input ib-instagram-email-input"}
                          placeholder={"Enter Email Here"}
                          defaultValue={iForm.getFieldValue("ib-community-email")}
                          onChange={e => iForm.setFieldsValue({"ib-community-email": e.target.value})}
                          required
                        />
                      </div>
                      <Form.Item
                        noStyle
                      >
                        <Form.Item
                          name={"ib-community-instagram"}
                          noStyle
                        >
                        </Form.Item>
                        <div
                          className={"ib-form-instagram-input-comp"}
                        >
                          <i
                            className="fa fa-ig-ib fa-2x fa-instagram"
                            aria-hidden="true"
                          />
                          <input
                            type="text"
                            id="ib-instagram-username-input"
                            className="ib-input ib-instagram-username-input"
                            placeholder={"Your Instagram Username (Optional)"}
                            onChange={e => iForm.setFieldsValue({"ib-community-instagram": e.target.value})}
                            defaultValue={iForm.getFieldValue("ib-community-instagram")}
                          />
                        </div>
                      </Form.Item>
                      <Form.Item
                        className={"has-text-centered"}
                        shouldUpdate
                      >
                        {() => {
                          return(
                            <button
                              style={{
                                backgroundColor: brandDetails.button_color,
                                color: getTextColor(brandDetails.button_color),
                              }}
                              disabled={load}
                              className={"is-uppercase px-5 has-text-weight-bold is-medium mt-4 button is-rounded"}
                            >
                              {brandDetails.button_text}
                            </button>
                          )
                        }}
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PicturesGalleria
          pictures={brandDetails.pictures}
          hide={true}
        />
        {brandDetails.faqs.show && (
          <FAQs
            campaign_id={brandDetails.id}
            faqItems={brandDetails.faqs.items}
          />
        )}
      </div>
    </div>
  )
}

export default QuizHome