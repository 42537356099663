import isMobile from "is-mobile";

type GridElementProps = {
  title: string,
  active: boolean,
  select: Function,
  remove: Function,
  disable: boolean
}

const GridElement = (
  {
    title,
    active,
    select,
    remove,
    disable
  }: GridElementProps
) => {

  return(
    <div
      className={`column is-4 has-text-centered ${isMobile()?"py-1":""}`}
      key={title}
    >
      <div
        className={`is-clickable grid-text-content is-uppercase ${isMobile()?"p-2":"p-4"} ${disable ? "is-unselectable" : ""}`}
        style={{
          backgroundColor: "#F5F5F5",
          borderRadius: "20px",
          minHeight: "80px",
          // color: active ? "#6C63FF" : "#A1A1A1",
          color: active ? "#000" : "#A1A1A1",
          fontWeight: active ? 700 : 500,
          position: "relative",
          wordBreak: "break-word"
        }}
        onClick={() => {
          if(!disable) {
            active ? remove(title) : select(title)
          }
        }}
      >
        {
          active && (
            <i
              className="fa fa-x fa-check"
              aria-hidden="true"
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: "24px",
                height: "24px",
                // backgroundColor: "#6C63FF",
                backgroundColor: "#000",
                color: "#fff",
                borderRadius: "30px",
                paddingLeft: "3px",
                paddingTop: "3px"
              }}
            />
          )
        }
        {title}
      </div>
    </div>
  )
}

export default GridElement