import
  communityDetails from "../../Common/Models/CommunityDetails";
import {useEffect, useState} from "react";
import QuizQuestionModel from "../../Common/Models/QuizQuestion"
import correctAud from '../../Common/Sounds/Correct-answer.mp3'
import wrongAud from '../../Common/Sounds/Wrong-answer.mp3'
import Sleep from "../../Common/Utils/Sleep";
import GridElement from "./GridElement";
import isMobile from "is-mobile";

type GridBoxProps = {
  status: 0 | 1 | 2 | 3,
  brandDetails: communityDetails | null,
  complete: Function
}

const GridBox = (
  {
    status,
    brandDetails,
    complete
  }: GridBoxProps
) => {
  const [items, setItems] = useState<string[]>([])
  const [score, setScore] = useState(0)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [maxScore, setMaxScore] = useState(0)
  const [startTime, setStartTime] = useState(Date.now())

  const additem = (a: string) => {
    setSelectedItems([...selectedItems, a])
  }

  const removeItem = (a: string) => {
    setSelectedItems(
      selectedItems.filter(function (item) {
        return item !== a
      })
    )
  }

  const start = () => {
    let boxes = [
      "DRANK COFFEE",
      "READ THE NEWS",
      "ATE BREAKFAST",
      "Woke up",
      "Free Spot",
      "Fell Back Asleep",
      "Snoozed Alarm",
      "Went to Work",
      "Scrolled Socials"
    ]
    setItems(boxes)
  }

  useEffect(() => {
    start()
  }, [])

  const done = () => {
    complete()
  }

  const ELEMENT_CLASSNAME = `${isMobile()?"pt-1":"pt-6"} columns is-variable is-1-mobile is-mobile is-multiline`
  const HEAD_CLASSNAME = `title is-size-5 has-text-centered heading ${isMobile()?"mb-2":""}`
  const SECOND_HEAD_CLASSNAME = `"title pb-3 is-size-4 is-size-5-mobile has-text-weight-medium px-2 has-text-centered heading"`

  if(status === 3 || !brandDetails) return null

  if(status === 1) {
    return(
      <div
        className={"ib-card-parent"}
      >
        <div
          className={"card ib-community-card switched"}
        >
          <div className={"card-content ib-card-content"}>
            <h3 className={HEAD_CLASSNAME}>
              #SullenBingo
            </h3>
            <h4 className={SECOND_HEAD_CLASSNAME}>
              What Morning Activites have you completed today?
            </h4>
            <div className={ELEMENT_CLASSNAME}>
              {items.map((i) => (
                <GridElement
                  title={i}
                  key={i}
                  active={selectedItems.indexOf(i) !== -1}
                  select={additem}
                  remove={removeItem}
                  disable={true}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <div
      className={"ib-card-parent"}
    >
      <div
        className={"card ib-community-card switched"}
      >
        <div className={"card-content ib-card-content"}>
          <h3 className={HEAD_CLASSNAME}>
            #SullenBingo
          </h3>
          <h4
            className={SECOND_HEAD_CLASSNAME}
          >
            What Morning Activites have you completed today?
          </h4>
          <div className={ELEMENT_CLASSNAME}>
            {items.map((i) => {
              return(
                <GridElement
                  title={i}
                  key={i}
                  active={selectedItems.indexOf(i) !== -1}
                  select={additem}
                  remove={removeItem}
                  disable={false}
                />
              )
            })}
          </div>
          <div className={`${isMobile()?"":"py-5"} has-text-centered`}>
            <button
              className={`button is-medium is-rounded is-dark px-6 has-text-weight-medium is-uppercase`}
              onClick={() => done()}
            >
              next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GridBox